import { useEffect, useState } from 'react';
import ForestSlider from '../Components/ForestSlider'
import Footer from '../Components/Footer'
import Castellano from '../Components/Castellano'
import Language from '../Components/Language'
import Euskera from '../Components/Euskera'
import Logo from '../Components/Logo'
import { LogoImg } from '../styles/Container';
import logo from '../asset/images/logo_designed.png'

const Home = () => {
    const [Lang, setLang] = useState('castellano');
    const [showLogo, setShowLogo] = useState(true);
    const [Slide, setSlide] = useState(0);

    useEffect(() => {
        if (Slide === 4) {
            setShowLogo(false)
        } else {
            setShowLogo(true)
        }
    }, [Slide])
    
    
    return (
        <div style={{position:'relative'}}>
            <ForestSlider setSlide={(sl) => setSlide(sl)}/>

            {/* {showLogo && <Logo/>} */}
            {showLogo && <LogoImg src={logo} style={{position:'absolute', top:'-5%', left:'-5%', zIndex:'50'}}/> }

            <Language Lang={Lang} setLang={(lang) => setLang(lang)}/>

            {Lang === "castellano" && <Castellano Slide={Slide}/>}

            {Lang === "euskera" && <Euskera Slide={Slide}/>}

            <Footer contact={Lang === "castellano" ? "Contacto" : "Harremanetarako"}/>
        </div>
    )
}

export default Home
