import tronc from "../asset/images/Tronc.png"
import arrels from "../asset/images/Arrels.png"

const Euskera = ({Slide}) => {
  return (
    <>
        {/* {Slide !== 4 && <div id="euskera1" style={{position:'absolute', top:'0', left:'0', zIndex:'3', height:'100vh',width:'100vw'}}>

            <div style={{position:'absolute', right:'2%', top:'2%', justifyContent:'right'}}>
                <a href='#productora'>
                    <div className="bookmarks">
                        Ekoiztetxea<img src={tronc} height='30vh' width='auto' style={{marginTop:'-4%'}}/>
                    </div>
                </a>
                <br/>
                <a href='#nosotras'>
                    <div className="bookmarks">
                        Taldea<img src={arrels} height='30vh' width='auto' style={{marginTop:'-4%'}}/>
                    </div>
                </a>
            </div>
        </div>} */}


        <div id="euskera2">
            <div style={{padding:'10vh 5vw', paddingRight:'2vw'}}>
                <div style={{display:'flex', justifyContent:'space-between'}} id='productora'>
                    <div style={{width:'70%'}}>

                        <h2>Ekoiztetxea</h2>

                        <div className="paragraph" style={{marginLeft:"2vw"}}>
                            Ametza gure bazterretan oso ohikoa<br/>
                            den zuhaitz baten izena da, nahiz eta jende<br/>
                            askok ez ezagutu hitz hau.
                        </div>
                        <div className="paragraph" style={{paddingLeft:"25vw"}}>
                            Haritza hitza, aldiz, askoz ezagunagoa zaigu,<br/>
                            baina hitz orokorra izanik, ez du gure basoen<br/>
                            aberastasun guztia islatzen.
                        </div>
                        <div className="paragraph" style={{paddingLeft:"50vw"}}>
                            Ametza betidanik egon da gure basoetan,<br/>
                            eta haritz mota bat izanik, beste zuhaitz eta<br/>
                            landare askorekin eratu ditu gure bazterrak.
                        </div>

                        <div className="paragraph" style={{ marginTop:'15vh'}}>
                            Guk horrela ulertzen dugu sorkuntza artistikoa, ekosistema baten parte bezala.
                            Gizartea baso bezala ulerturik, agerian baina izkutuan dauden gai sozialak
                            aldarrikatu eta azaleratu nahi ditugu.
                        </div>

                        <div className="paragraph">
                            Gure gizartearen konplexutasuna eta aberastasuna bere osotasunean ulertu nahi dugu
                            gure lanen bitartez, lur azpian elkarlotzen diren sustraien antzera.
                        </div>

                        <div className="paragraph">
                            Ideia hau beti presente dugu gure lanetan, gaiak ulertzeko modu hau etengabe landuz atal
                            guztietan, hasieratik amaierara arte.
                        </div>

                    </div>
                    <div>
                        {/* <img src={tronc} height='50vh' width='auto' /> */}
                    </div>
                </div>

                <div style={{display:'flex', justifyContent:'space-between', marginTop:"5vh"}} id='nosotras'>
                    <div style={{width:'70%'}}>

                        <h2>Taldea</h2>

                        <p className="paragraph" style={{marginLeft:"2vw"}}>
                            Gasteizko talde bat gara,<br/>
                            eta elkarrekin daramagu<br/>
                            elkarrekin ikasi genuenetik.
                        </p> 

                        <p className="paragraph" style={{marginLeft:"40vw"}}>
                            Gure adiskidetasuna ardatz harturik,<br/>
                            batera ateratzen ditugu gure proiektuak
                        </p>

                        <p className="paragraph" style={{marginLeft:"11.5vh", width:"80vw"}}>
                            Taldekide bakoitza ikus-entzunezkoetako<br/>
                            atal ezberdin batean espezializatua dagoen heinean,<br/>
                                <p style={{marginLeft:"2.5vw", marginTop:"5px"}}>
                                    proiektu bakoitza gure kabuz <br/>
                                    aurrera ateratzen dugu, elkarri lagunduz<br/>
                                    eta proiektuak era kolektiboan ulertuz,
                                </p>
                                <p style={{marginLeft:"17vw"}}>
                                    bai gure artean elkarrekin lan egiten<br/>
                                    dugulako bai eta jasotzen ditugun gaiak prozesu<br/>
                                    kolektiboak izaten direlako.
                                </p>
                        </p>
                    </div>
                    <div>
                        {/* <img src={arrels} height='50vh' width='auto' /> */}
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default Euskera