import styled from "styled-components";
import { device } from './Device';

export const LogoImg = styled.img`
   position:absolute;
   z-index:50; 
   width: auto;

    @media ${device.mobileS} {
      height: 3%;
      margin-top: 25vh;
       margin-left:-3vw;
    }

    @media ${device.mobileL} {
      height: 5%;
      margin-top: 25vh;
       margin-left:-3vw;
    }

    @media ${device.laptop} {
       height: 20%;
       top:-5%; 
       left:-12.5%; 
       margin-top: 0;
       margin-left:-3vw;
    }
`
