import tronc from "../asset/images/Tronc.png"
import arrels from "../asset/images/Arrels.png"

const Castellano = ({Slide}) => {
    console.log(Slide)
  return (
    <>
        {/* {Slide !== 4 && <div id="castellano-bm" style={{position:'absolute', top:'0', left:'0', zIndex:'3', height:'100vh',width:'100vw'}}>

            <div style={{position:'absolute', right:'2%', top:'2%', justifyContent:'right'}}>
                <a href='#productora'>
                    <div className="bookmarks">
                        Productora<img src={tronc} height='30vh' width='auto' style={{marginTop:'-4%'}}/>
                    </div>
                </a>
                <br/>
                <a href='#nosotras'>
                    <div className="bookmarks">
                        Nosotras<img src={arrels} height='30vh' width='auto' style={{marginTop:'-4%'}}/>
                    </div>
                </a>
            </div>
        </div>} */}


        <div id="castellano-content">
            <div style={{padding:'10vh 5vw', paddingRight:'2vw'}}>
                <div style={{display:'flex', justifyContent:'space-between'}} id='productora'>
                    <div style={{width:'70%'}}>

                        <h2>Productora</h2>
                        
                        <div className="paragraph" style={{marginLeft:"2vw"}}>Ametza, en castellano melojo, es el nombre de un árbol <br/>muy común en nuestro entorno, aunque mucha gente <br/>no conozca esta palabra.</div>
                        <div className="paragraph" style={{paddingLeft:"25vw"}}>La palabra roble, siendo mucho más conocida, <br/>también es más genérica, lo que no refleja la diversidad <br/>y riqueza de nuestros bosques.</div>
                        <div className="paragraph" style={{paddingLeft:"50vw"}}>El melojo, siendo un tipo de roble, siempre ha tenido mucha <br/>presencia en nuestros bosques, formando parte de nuestro <br/>entorno natural junto a otros árboles y plantas. </div>

                        <div style={{width:"43vw", marginTop:'15vh'}} className="paragraph">Nosotros entendemos así la creación artística. Entendiendo la sociedad como un ecosistema, queremos sacar a la luz y reivindicar temáticas sociales que nos afecten.</div>
                        <div className="paragraph">Queremos entender la riqueza y complejidad del entorno social en su conjunto a través de nuestros trabajos.</div>
                        <div className="paragraph">Cómo las raíces que se entremezclan bajo tierra, enfocamos los trabajos de manera sólida desde el inicio, trabajando la idea desde la preproducción hasta el final del proyecto.</div>

                    </div>
                    <div>
                        {/* <img src={tronc} height='50vh' width='auto' /> */}
                    </div>
                </div>

                <div style={{display:'flex', justifyContent:'space-between', marginTop:"5vh"}} id='nosotras'>
                    <div style={{width:'70%'}}>

                        <h2>Nosotras</h2>

                        <p className="paragraph" style={{marginLeft:"2vw"}}>
                            Somos un grupo de Gasteiz,<br/>
                            aunque llevamos juntas<br/>
                            desde que estudiamos en la universidad.
                        </p> 

                        <p className="paragraph" style={{marginLeft:"45vw"}}>
                            Tomando como punto de partida nuestra amistad,<br/> 
                            sacamos adelante nuestros proyectos juntas.
                        </p>

                        <p className="paragraph" style={{marginLeft:"11.5vh", width:"80vw"}}>
                            Como cada una de nosotras está especializada<br/>
                            en un apartado diferente del mundo audiovisual,<br/>
                                <p style={{marginLeft:"2.5vw", marginTop:"5px"}}>
                                    tenemos la capacidad de sacar adelante <br/>
                                    cualquier proyecto, ayudándonos entre nosotras <br/>
                                    y realizando los trabajos desde un enfoque colectivo,
                                </p>
                                <p style={{marginLeft:"23vw"}}>
                                    ya sea porque trabajamos juntas <br/>
                                    o porque solemos seguir procesos sociales colectivos<br/>
                                    en nuestros trabajos. 
                                </p>
                        </p>
                    </div>
                    <div>
                        {/* <img src={arrels} height='50vh' width='auto' /> */}
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default Castellano