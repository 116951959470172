import React from 'react'

const Language = ({Lang, setLang}) => {
  return (
    <div style={{position:'relative', textAlign:"right", padding:"5vh 5vw 0"}}>
        {Lang==="castellano" 
        ? <b>
            <a onClick={() => setLang("castellano")}>
              Castellano
            </a>
          </b> 
        : <a onClick={() => setLang("castellano")}>
            Castellano
          </a>} 
          
          &emsp;| &emsp; 
          
          {Lang === "euskera" 
          ? <b>
              <a onClick={() => setLang("euskera")}>
                Euskara
              </a>
            </b> 
          : <a onClick={() => setLang("euskera")}>
              Euskara
            </a> }
    </div>
  )
}

export default Language