import forest1 from "../asset/images/slider/sky.jpg"
import forest2 from "../asset/images/slider/lands.png"
import forest3 from "../asset/images/slider/sky2.jpg"
import forest4 from "../asset/images/slider/branches.png"
import forest5 from "../asset/images/slider/FOTO-VIDEO.png"
import pointer from "../asset/images/cursor.png"
import play from "../asset/images/play.png"

// import forest1 from "../asset/images/slider/forest1.JPG"
// import forest2 from "../asset/images/slider/forest2.JPG"
// import forest3 from "../asset/images/slider/forest3.JPG"
// import forest4 from "../asset/images/slider/forest4.JPG"
// import forest5 from "../asset/images/slider/forest5.JPG"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useState } from "react"

const ForestSlider = ({setSlide}) => {
    const [ActiveSlide, setActiveSlide] = useState(0)

    return (
        <header style={{height:'100vh', width:'100vw', overflow:'hidden'}}>
            <Swiper
            slidesPerView={1}
            loop={false}
            pagination={{clickable: true}}
            modules={[Pagination,Autoplay]}
            // className={`mySwiper ${styledClasses}`}
            autoplay={{delay: 3000}}
            // autoplay={{delay: ActiveSlide === 4 ? (18*60+22)*1000 : 3000}}
            onSlideChange={(swiper) => {setActiveSlide(swiper.activeIndex); setSlide(swiper.activeIndex)}}
            // onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide>
                {/* <img src={forest1} width='100%' height='100%'  /> */}
                <div style={{height:"100vh", width:"100vw", backgroundImage:"url("+forest1+")", backgroundSize:"cover", backgroundPosition:"right"}}></div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{height:"100vh", width:"100vw", backgroundImage:"url("+forest2+")", backgroundSize:"cover", backgroundPosition:"center"}}></div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{height:"100vh", width:"100vw", backgroundImage:"url("+forest3+")", backgroundSize:"cover", backgroundPosition:"center"}}></div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{height:"100vh", width:"100vw", backgroundImage:"url("+forest4+")", backgroundSize:"cover", backgroundPosition:"center"}}></div>
            </SwiperSlide>
            {/* <SwiperSlide>
                <iframe width="1280" height="720" autoplay style={{height:"100vh", width:"100vw"}} src="https://www.youtube.com/embed/QxX4qGz_qao?autoplay=1" title="Erroak" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </SwiperSlide> */}
            <SwiperSlide>
                <a target="_blank" href="https://www.youtube.com/embed/QxX4qGz_qao?autoplay=1">
                    <div style={{position:"absolute", width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} >
                        <img src={play} style={{width:"8vw"}} />
                    </div>
                    {/* <img src={pointer} style={{position:"absolute", height:"12vh", top:"58vh", left:"58vw"}} /> */}
                    {/* <p src={pointer} style={{position:"absolute", top:"67vh", left:"58vw", fontSize:"4vh", fontFamily:"Grotesk"}}>Click me</p> */}
                    <div style={{height:"100vh", width:"100vw", backgroundImage:"url("+forest5+")", backgroundSize:"cover", backgroundPosition:"center"}}></div>
                </a>
            </SwiperSlide>
        </Swiper>
      </header>
    ) 
}

export default ForestSlider
