import {AiOutlineYoutube} from 'react-icons/ai'
import {GrVimeo} from 'react-icons/gr'
import {BsInstagram} from 'react-icons/bs'

const Footer = ({contact}) => {

  return (
            <footer id="footer" style={{marginTop:'10vh', marginLeft:"4vw", marginRight:"5vw", display:'flex', alignContent:'center', justifyContent:'space-between'}}>
                <div>
                    <p style={{fontSize:'2vh', lineHeight:"2vh", textTransform: "uppercase"}}>{contact}</p>
                </div>
                <div>
                    <a style={{}} href='mailto:ametzafilms@gmail.com'>
                        <p style={{fontSize:'1.75vh', lineHeight:"2vh", color:"white"}}>
                            ametzafilms@gmail.com
                        </p>
                    </a>
                </div>
                <div>
                    <p>
                        <a href='https://vimeo.com/user185773951'>
                            <GrVimeo style={{color:"white", fontSize:"2vh", lineHeight: "2vh"}}/>
                        </a>
                    </p>
                </div>
                <div>
                    <p>
                        <a href='https://www.instagram.com/ametza.films/?hl=es'>
                            <BsInstagram style={{color:"white", fontSize:"2vh", lineHeight: "2vh"}}/>
                        </a>
                    </p>
                </div>
                <div>
                    <p>
                        <a href='https://www.youtube.com/@AmetzaFilms'>
                            <AiOutlineYoutube style={{color:"white", fontSize:"2.5vh", lineHeight: "2vh"}}/>
                        </a>
                    </p>
                </div>
            </footer>
  )
}

export default Footer